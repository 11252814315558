<template>
  <div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable&&isWriting"
        label="허가서 첨부파일">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoJobPlan"
        :editable="editable&&isWriting"
        label="작업계획서">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoExtinguisher"
        :editable="editable&&isWriting"
        label="소화기 목록">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoEducationJournal"
        :editable="editable&&isWriting"
        label="굴착지반도면">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoDraw"
        :editable="editable&&isWriting"
        label="도면">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoSafetyGear"
        :editable="editable&&isWriting"
        label="안전보호구 목록">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoEtc"
        :editable="editable&&isWriting"
        label="기타">
      </c-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: 'swp-attach',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
        }
      }
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
  },
  data() {
    return {
      attachInfo: { // 허가서 첨부파일
        isSubmit: '',
        taskClassCd: 'SWP_RELATE_INFO',
        taskKey: '',
      },
      attachInfoJobPlan: { // 작업계획서
        isSubmit: '',
        taskClassCd: 'SWP_JOB_PLAN',
        taskKey: '',
      },
      attachInfoExtinguisher: { // 소화기목록
        isSubmit: '',
        taskClassCd: 'SWP_EXTINGUISHER',
        taskKey: '',
      },
      attachInfoEducationJournal: { // 굴착지반도면
        isSubmit: '',
        taskClassCd: 'SWP_EDUCATION_JOURNAL',
        taskKey: '',
      },
      attachInfoDraw: { // 도면
        isSubmit: '',
        taskClassCd: 'SWP_DRAW',
        taskKey: '',
      },
      attachInfoSafetyGear: { // 안전보호구 목록
        isSubmit: '',
        taskClassCd: 'SWP_SAFETY_GEAR',
        taskKey: '',
      },
      attachInfoEtc: { // 기타
        isSubmit: '',
        taskClassCd: 'SWP_ETC',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'workPermit.sopWorkPermitId'() {
      this.setTaskKey();
    },
    'saveCall.saveCallData'() {
      this.attachInfo.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoJobPlan.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoExtinguisher.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoEducationJournal.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoDraw.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoSafetyGear.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoEtc.isSubmit = this.$_.clone(this.saveCall.saveCallData)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.workPermit && this.workPermit.sopWorkPermitId ? this.workPermit.sopWorkPermitId : '';
      this.$set(this.attachInfo, 'taskKey', taskKey)
      this.$set(this.attachInfoJobPlan, 'taskKey', taskKey)
      this.$set(this.attachInfoExtinguisher, 'taskKey', taskKey);
      this.$set(this.attachInfoEducationJournal, 'taskKey', taskKey);
      this.$set(this.attachInfoDraw, 'taskKey', taskKey);
      this.$set(this.attachInfoSafetyGear, 'taskKey', taskKey);
      this.$set(this.attachInfoEtc, 'taskKey', taskKey);
    }
  }
};
</script>
